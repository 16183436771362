import { useCallback, useEffect, useState } from "react";
import { EditorProps } from "../../types";
import { SingleMediaEditor as ContentfulSingleMediaEditorField } from "@contentful/field-editor-reference";
import { FormControl } from "@contentful/f36-components";
import { AssetProps, SysLink } from "contentful-management";

type MimetypeGroup =
  | "attachment"
  | "plaintext"
  | "image"
  | "audio"
  | "video"
  | "richtext"
  | "presentation"
  | "spreadsheet"
  | "pdfdocument"
  | "archive"
  | "code"
  | "markup";

type AssetFile = AssetProps['fields']['file'];

const LinkAssetField = ({
  validations,
  sdk,
  clients,
  handleValidationUpdate,
}: EditorProps) => {
  const [widthErrorMessage, setWidthErrorMessage] = useState("");
  const [heightErrorMessage, setHeightErrorMessage] = useState("");
  const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState("");
  const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState("");

  const getAssetData = useCallback(async (): Promise<Record<string, any> | null> => {
    try {
      const existingFieldValue: SysLink = sdk.field.getValue() || [];
      if (!existingFieldValue?.sys?.id) {
        console.warn("[CF app] Asset field value is missing or invalid.");
        return null;
      }

      const asset = await clients.environment.getAsset(existingFieldValue.sys.id);
      return asset?.fields || null;
    } catch (error) {
      console.error("[CF app] Error fetching asset data:", error);
      return null;
    }
  }, [clients.environment, sdk.field]);

  const validateImageDimensions = useCallback((file: AssetFile | undefined): boolean => {
    const { width, height } =
    validations?.find((validation) => validation.assetImageDimensions)
      ?.assetImageDimensions || {};

    const assetWidth = file?.[sdk.field.locale]?.details?.image?.width ?? 0;
    const assetHeight = file?.[sdk.field.locale]?.details?.image?.height ?? 0;

    let hasError = false;
    let widthErrorMessage = "";
    let heightErrorMessage = "";

    if (assetWidth) {
      // Validate width
      if (width?.min != null && assetWidth < width.min) {
        widthErrorMessage = `Image width must be at least ${width.min} px.`;
        hasError = true;
      } else if (width?.max != null && assetWidth > width.max) {
        widthErrorMessage = `Image width must be at most ${width.max} px.`;
        hasError = true;
      }
    }

    if (assetHeight) {
      // Validate height
      if (height?.min != null && assetHeight < height.min) {
        heightErrorMessage = `Image height must be at least ${height.min} px.`;
        hasError = true;
      } else if (height?.max != null && assetHeight > height.max) {
        heightErrorMessage = `Image height must be at most ${height.max} px.`;
        hasError = true;
      }
    }

    // Update error messages
    setWidthErrorMessage(widthErrorMessage);
    setHeightErrorMessage(heightErrorMessage);

    return hasError;
  }, [validations, sdk.field.locale]);

  const validateFileType = useCallback((file: AssetFile | undefined): boolean => {
    const fileType = file?.[sdk.field.locale]?.fileName?.split(".").pop();
    const linkMimetypeGroup: MimetypeGroup[] = (validations?.find(
      (validation) => !!validation.linkMimetypeGroup
    )?.linkMimetypeGroup || []) as MimetypeGroup[];

    if (
      fileType &&
      linkMimetypeGroup.length > 0 &&
      !isFileTypeInMimetypeGroup(fileType, linkMimetypeGroup)
    ) {
      setFileTypeErrorMessage(
        `[ ${linkMimetypeGroup.join(
          ", "
        )} ] are the only acceptable file types.`
      );
      return true;
    }

    setFileTypeErrorMessage("");
    return false;
  }, [sdk.field.locale, validations]);

  const validateFileSize = useCallback( (file: AssetFile | undefined): boolean => {
    const fileSize = file?.[sdk.field.locale]?.details?.size;
    const { max, min } =
    validations?.find((validation) => !!validation.assetFileSize)
      ?.assetFileSize || {};

    if (fileSize) {
      if (max !== undefined && fileSize > max * 1024) {
        setFileSizeErrorMessage(`File size must be at most ${max} KB`);
        return true;
      } else if (min !== undefined && fileSize < min * 1024) {
        setFileSizeErrorMessage(`File size must be at least ${min} KB`);
        return true;
      }
    }

    setFileSizeErrorMessage("");
    return false;
  }, [sdk.field.locale, validations]);

  const validate = useCallback(async () => {
    const assetFieldData = await getAssetData();

    const file: AssetFile = assetFieldData?.file;

    const fileTypeInvalid = validateFileType(file);
    const imageDimensionsInvalid = validateImageDimensions(file);
    const fileSizeInvalid = validateFileSize(file);

    sdk.field.setInvalid(
      imageDimensionsInvalid || fileTypeInvalid || fileSizeInvalid
    );

    if (handleValidationUpdate)
      handleValidationUpdate({
        imageDimensionsInvalid,
        fileTypeInvalid,
        fileSizeInvalid,
      });
  }, [getAssetData, validateImageDimensions, validateFileType, validateFileSize, sdk.field, handleValidationUpdate]);

  useEffect(() =>  {
    validate();
  }, [validate]);

  useEffect(
    () =>
      sdk.field.onValueChanged(() => {
        validate();
      }),
    [sdk.field, validate]
  );

  /**
   * @param fileType
   * @param mimetypeGroups
   */
  function isFileTypeInMimetypeGroup(
    fileType: string | undefined,
    mimetypeGroups: MimetypeGroup[]
  ): boolean {
    if (!fileType) return false;

    const groupMap: Record<MimetypeGroup, string[]> = {
      attachment: ["doc", "docx", "xls", "xlsx", "ppt", "pptx"],
      plaintext: ["txt"],
      image: ["jpg", "jpeg", "png", "gif", "bmp"],
      audio: ["mp3", "wav"],
      video: ["mp4", "avi", "mov"],
      richtext: ["rtf"],
      presentation: ["ppt", "pptx"],
      spreadsheet: ["xls", "xlsx"],
      pdfdocument: ["pdf"],
      archive: ["zip", "rar", "tar", "gzip"],
      code: ["js", "java", "cpp", "py"],
      markup: ["html", "xml", "svg"],
    };

    const fileExtension = fileType.toLowerCase().split(".").pop();
    if (!fileExtension) return false;

    return mimetypeGroups.some(
      (group) => group in groupMap && groupMap[group].includes(fileExtension)
    );
  }

  return (
    <>
      <ContentfulSingleMediaEditorField
        sdk={sdk}
        isInitiallyDisabled={false}
        viewType="card"
        parameters={{
          instance: {
            showCreateEntityAction: true,
            showLinkEntityAction: true,
            bulkEditing: true,
          },
        }}
      />
      {widthErrorMessage && (
        <FormControl.ValidationMessage>
          {widthErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {heightErrorMessage && (
        <FormControl.ValidationMessage>
          {heightErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {fileTypeErrorMessage && (
        <FormControl.ValidationMessage>
          {fileTypeErrorMessage}
        </FormControl.ValidationMessage>
      )}
      {fileSizeErrorMessage && (
        <FormControl.ValidationMessage>
          {fileSizeErrorMessage}
        </FormControl.ValidationMessage>
      )}
    </>
  );
};

export default LinkAssetField;

/**
 * A LinkAsset field with all possible standard validations looks like:
 * {
 *       "id": "LinkAsset",
 *       "name": "LinkAsset",
 *       "type": "Link",
 *       "localized": false,
 *       "required": true,
 *       "validations": [
 *         {
 *           "linkMimetypeGroup": [
 *             "attachment",
 *             "plaintext",
 *             "image",
 *             "audio",
 *             "video",
 *             "richtext",
 *             "presentation",
 *             "spreadsheet",
 *             "pdfdocument",
 *             "archive",
 *             "code",
 *             "markup"
 *           ]
 *         },
 *         {
 *           "assetImageDimensions": {
 *             "width": {
 *               "min": 10,
 *               "max": null
 *             },
 *             "height": {
 *               "min": 28,
 *               "max": null
 *             }
 *           }
 *         },
 *         {
 *           "assetFileSize": {
 *             "min": 3,
 *             "max": 51200
 *           }
 *         }
 *       ],
 *       "disabled": false,
 *       "omitted": false,
 *       "linkType": "Asset"
 *     }
 *   ],
 */
