import { FieldExtensionSDK } from "@contentful/app-sdk";
import { ViewType } from "@contentful/field-editor-reference/dist/types";
import {
  ClientAPI,
  ContentTypeFieldValidation,
  Environment,
  Space,
} from "contentful-management";

// Scoped down to the ones we actually are able to support
export const EditorInterfaces = {
  assetLinkEditor: "assetLinkEditor",
  // assetLinksEditor: "assetLinksEditor",
  // assetGalleryEditor: "assetGalleryEditor",
  boolean: "boolean",
  // datePicker: "datePicker",
  // entryLinkEditor: "entryLinkEditor",
  entryLinksEditor: "entryLinksEditor",
  // entryCardEditor: "entryCardEditor",
  // entryCardsEditor: "entryCardsEditor",
  numberEditor: "numberEditor",
  // rating: "rating",
  // locationEditor: "locationEditor",
  // objectEditor: "objectEditor",
  // urlEditor: "urlEditor",
  // slugEditor: "slugEditor",
  // listInput: "listInput",
  // checkbox: "checkbox",
  tagEditor: "tagEditor",
  // multipleLine: "multipleLine",
  // markdown: "markdown",
  singleLine: "singleLine",
  dropdown: "dropdown",
  radio: "radio",
  richTextEditor: "richTextEditor",
} as const;

export type EditorInterfaceWidgetOptions =
  Exclude<(typeof EditorInterfaces)[keyof typeof EditorInterfaces], 'd'>;

export type EditorProps = Condition & {
  editorInterfaceWidget?: EditorInterfaceWidgetOptions;
  sdk: FieldExtensionSDK;
  clients: ContentfulManagementClients;
  handleValidationUpdate?: (update: ValidationAction) => void;
};

export type EditorComponent = (props: EditorProps) => JSX.Element;

type LinkContentTypeValidation = {
  linkContentType: string[];
}

export type NodeValidation = {
  nodes?: {
    "embedded-entry-block"?: [LinkContentTypeValidation]
    "embedded-entry-inline"?: [LinkContentTypeValidation]
    "entry-hyperlink"?: [LinkContentTypeValidation]
  }
}

export type Condition = {
  disabled?: boolean;
  editorInterfaceWidget?: EditorInterfaceWidgetOptions;
  validations?: (ContentTypeFieldValidation & NodeValidation)[];
  itemValidations?: ContentTypeFieldValidation[];
  params?: EntityActions & {
    viewType?: ViewType;
    trueLabel?: string
    falseLabel?: string
  };
};

export type OnDisable = {
  action: 'set' | 'clear';
  value?: unknown;
};

export type Conditions = {
  baseEditorInterfaceWidget?: EditorInterfaceWidgetOptions;
  dependentFieldId: string;
  default?: Condition;
  onDisable?: OnDisable;
  values: {
    [value: string]: Condition;
  };
};

export type ConditionalFieldMetadata = {
  [contentType: string]: {
    [conditionalField: string]: Conditions;
  };
};

export type EntityActions = {
  showCreateEntityAction?: boolean;
  showLinkEntityAction?: boolean;
  bulkEditing?: boolean;
};

export type ContentfulManagementClients = {
  client: ClientAPI;
  space: Space;
  environment: Environment;
};

export type ValidationAction = {
  [key: string]: boolean;
};
